import Vue from 'vue'
import Vuex from 'vuex'
import {get} from '@/axios/index'

import createPersistedState from "vuex-persistedstate"
Vue.use(Vuex)

const store = new Vuex.Store({
  state:{
    user:{},
    plugins: [createPersistedState({
      paths: ["user"]
    })],
  },
  mutations:{
    CHANGEUSER(state,val){
      get('/community/commodity/user_info').then(res=>{
        state.user = res.data
        localStorage.setItem('face',res.data.face)
      })
    }
  },
  actions:{
  },
  modules:{},
})
export default store