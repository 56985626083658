import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'element-ui/lib/theme-chalk/index.css'
import ElementUI from 'element-ui'
import {post,get,shopPost} from './axios'
import { Base64 } from 'js-base64';

Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.prototype.$post = post
Vue.prototype.$get = get
Vue.prototype.$shopPost = shopPost
Vue.prototype.$Base64 = Base64
new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
