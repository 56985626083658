<template>
    <el-row :gutter="20" class="foot_box">
        <div style="height:9rem">
            <el-col :xs="24" :sm="12" :md="5" :lg="5">
        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="24" class="logoClass logos">
            <img src="../assets/black-bom.png" alt="" />
          </el-col> 
        </el-row>
      </el-col>
      <el-col :xs="24" :sm="12" :md="19" :lg="19">
        <el-row>
          <el-col :xs="24" :sm="24" :md="8" :lg="5" class="logoBox">
            <p><a href="https://www.zhihu.com/people/83da3bae7dd0da3614085dc8014ec139" target="_blank"><img class="logoZhi" src="../assets/zhi.png" alt="" /></a></p>
            <p>
              <img class="logoXin" src="../assets/weixin.png" alt="" />
              <img src="../assets/iotsec-zonelogo.png" class="PosiContactChart" />
            </p>
            <p><a href="https://b23.tv/P3CEwKV" target="_blank"><img class="logoBili" src="../assets/bili.png" alt="" /></a></p>
          </el-col>
          <el-col :xs="12" :sm="12" :md="8" :lg="5">
            <h4 class="title"><router-link to="/aboutUs" class="link">关于我们</router-link></h4> 
          </el-col>
          <el-col :xs="12" :sm="12" :md="8" :lg="5">
            <h4 class="title"><router-link to="/joinUs" class="link">加入我们</router-link></h4>
          </el-col>
          <el-col :xs="12" :sm="12" :md="8" :lg="5">
            <h4 class="title"><router-link to="/cooperation" class="link">商务合作</router-link></h4>
          </el-col>
          <el-col :xs="12" :sm="12" :md="8" :lg="4">
            <h4 class="title"><a href="https://www.xinruisec.com/" target="_blank" class="link">信睿网络</a></h4>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24">
            <el-row :gutter="20">
              <el-col :xs="24" :sm="12" :md="7" :lg="5"> 
                  <h4 class="title" v-if="firdentData.length != 0">友情链接</h4>
              </el-col>
              <el-col :xs="24" :sm="24" :md="17" :lg="19"> 
                <el-row
                  :gutter="20"
                  class="footContent"
                  v-if="firdentData.length != 0"
                >
                  <el-col class="fridentLink linkBox" > 
                    <span v-for="item in firdentData" :key="item.link_name" class="imageBox">
                      <a :href="item.link_url" target="_blank" >{{ item.link_name }}</a>
                      <img :src="item.link_image" class="link_image"> 
                    </span>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
      </el-col>
        </div>
      
      <p class="line"></p>
      <div class="text">
        <span>版权所有 © {{ year }} IOTsec-Zone</span>
        <span class="VerticalLine">|</span>
        <span><img src="../assets/ghs.png" /></span>
        <span>
          <el-link
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=22017202000209"
            target="_blank"
            :underline="false"
            >吉公网安备 22017202000282号</el-link
          >
        </span>
        <span class="VerticalLine">|</span>
        <span>
          <el-link
            href="https://beian.miit.gov.cn/#/Integrated/index"
            target="_blank"
            :underline="false"
            >吉ICP备19001006号-2</el-link
          >
        </span>
      </div>
    </el-row>
  </template>
  
  <script>
  import { ref, getCurrentInstance, onBeforeMount } from "vue";
  
  export default {
    setup() {
      const { proxy } = getCurrentInstance();
      onBeforeMount(() => {
        listDataFun();
      });
      const listDataFun = () => {
        proxy.$get("/community/home_info").then((res) => {
          firdentData.value = res.data.footer.links;  
        });
      };
      const year = ref(new Date().getFullYear());
      const firdentData = ref([]); 
      return {
        year,
        firdentData, 
      };
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .logos{
    margin-top: 15px !important;
  }
  .logoBox p {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: #b9b9b9;
    display: inline-block;
    text-align: center;
    cursor: pointer;
    transition: all 0.5s;
  }
  .logoBox p:hover{
    background-color: #838383;
  }
  .logoBox p:nth-child(1) {
    line-height: 46px;
    margin-right: 10px;
  }
  .logoBox p:nth-child(1) img {
    width: 20px;
  }
  .logoBox p:nth-child(2) {
    line-height: 46px;
    margin-right: 10px;
    position: relative;
  }
  .logoBox p:nth-child(2) img {
    width: 22px;
  }
  .logoBox p:nth-child(3) {
    line-height: 43px;
  }
  .logoBox p:nth-child(3) img {
    width: 26px;
  }
  .logoBox p:nth-child(2):hover .PosiContactChart {
    display: block;
  }
  .PosiContactChart {
    width: 80px !important;
    height: 80px !important;
    position: absolute;
    left: 0;
    top: 36px;
    display: none;
    z-index: 99;
  }
  .logoBox p img {
    width: 25px;
  }
  .foot_box {
    width: 100%;
    overflow: hidden;
    background-color: #141414;
    padding-top: 50px;
    padding-left: 12.5%;
    padding-right: 12.5%;
    text-align: left;
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .logoClass,
  .logoBox {
    margin-top: 20px;
  }
  .logoClass img {
    width: 140px;
  }
  .line {
    width: 100%;
    height: 0;
    border-top: 1px solid #aaaaaa;
    margin: 50px auto;
  }
  .text {
    margin: auto;
    margin-bottom: 10px;
    color: #aaaaaa;
    font-size: 14px;
    overflow: hidden;
    text-align: center;
  }
  .text a {
    color: #aaaaaa;
    font-size: 14px;
  }
  .VerticalLine {
    margin-left: 5px;
    margin-right: 5px;
  }
  .text img {
    vertical-align: middle;
    margin-right: 5px;
  }
  .fridentLink {
    text-align: left;
    margin-bottom: 20px;
  }
  .linkBox img {
    width: 100%;
    height: 40px;
    cursor: pointer;
    border: 2px solid #4e4e4e;
    transition: all 0.5s;
  }
  .linkBox img:hover {
    border-color: #ac9456;
  }
  .linkBox{
    text-align:justify;
    margin: 16px auto; 
    position: relative;
  }
  .imageBox{
    position: relative;
  }
  .imageBox:hover .link_image{
    display: block;
  }
  .link_image{
    position: absolute;
    width: 100px!important;
    display: none;
    left:0;
    background-color: #6b6b6b;
    z-index: 99;
    top: 18px;
  }
  .linkBox a{
    margin-right: 1rem;
    color: #6b6b6b;
    text-decoration: none;
    line-height: 35px;
  }
  .linkBox a:hover{
    color: #ac9456;
  }
  
  :deep().el-button--text {
    color: #8a8a8a;
    transition: all 0.5s;
  }
  :deep().el-button--text:hover {
    color: #ac9456;
  }
  .title {
    color: #ffffff;
    margin: 20px auto;
    font-size: 1.5rem;
    text-align: left;
    font-weight: 300;
    margin-left: 3px;
    font-size: 16px;
  }
  .title a{
    color: #ffffff;
    display: inline-block;
    font-size: 16px;
  }
  .title a:hover{
    color: #ac9456;
  }
  .link {
    text-align: left;
    text-decoration: none;
    color: #6b6b6b;
    display: block;
    line-height: 35px;
  }
  .link:hover {
    color: #ac9456;
  }
  .icon-weixin6,
  .icon-qq4 {
    display: inline-block;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border-radius: 50%;
    border: 1px solid #6b6b6b;
    margin-right: 10px;
    cursor: pointer;
    color: #6b6b6b;
    transition: all 0.5s;
  }
  .iconfont {
    font-size: 20px;
  }
  .contact {
    margin-top: 10px;
  }
  .icon-weixin6:hover {
    color: #fff;
    background: #01c924;
  }
  .icon-qq4:hover {
    color: #fff;
    background: #de1f2b;
  }
  .iconfontbox {
    display: inline-block;
    position: relative;
  }
  .PosiContact {
    position: absolute;
    left: 0;
    display: none;
    z-index: 99;
  }
  .PosiContact img {
    width: 80px;
    height: 80px;
  }
  .qq:hover .PosiContact {
    display: block;
  }
  @media (max-width: 540px) {
    .foot_box {
      width: 100%;
      padding-left: 10%;
      padding-right: 10%;
    } 
  }
  @media (max-width: 760px) { 
    .logoClass img{
      width: 200px;
    }
  }
  </style>