<template>
  <div id="app">
    <nav_head class="nav" />
    <router-view style="padding-top:7rem;padding-bottom:2rem"/>
    <bottom v-show="$route.path!='/cart'" />
  </div>
</template>

<script>
  import nav_head from './components/nav.vue'
  import bottom from './components/footer.vue'
export default{
  name: "App",
  components:{nav_head,bottom},
  data(){
    return{
      flag:true,
      token:localStorage.getItem('Token')
    }
  },
  created(){
    if(localStorage.getItem('Token')){
      this.getInfo()
    }
  },
  watch: {
    $route(to) {
      this.path = to.path;
    },
  },
  methods:{
    getInfo(){
      this.$get('/community/commodity/user_info').then(res=>{
        localStorage.setItem('face',res.data.face)
      })
    }
  }
}

</script>

<style>
	/* .nav{
		width: 100%;
		position: fixed;
		top: 0;
		left: 0;
	} */
  *{
    padding: 0;
    margin: 0;
  }
  ul{
    list-style: none;
  }
</style>
