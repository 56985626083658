<template>
  <div class="home">
    <!-- <p class="tit" style="margin-top:3rem">PLEASE PICK YOUR FAVORITE COMMODITY!</p>
      <p class="tit">Hardware equipment</p> -->
    <p class="tit" style="margin-top:3rem">欢迎来到IOTsec-Zone商城</p>
      <p class="tit" style="font-size:18px">PLEASE PICK YOUR FAVORITE COMMODITY!</p>
    <div style="margin:0 13rem;" v-for="(v,i) in tableData" :key="i">
      <div class="tab" style="margin-top:40px">
        <span>{{i}}</span>
        <span @click="golist">MORE</span>
      </div>
      <ul>
        <li class="list" v-for="(item,index) in v.slice(0,3)" :key="index">
          <div >
            <a class="shop_img" :href="'/detail?id='+item.id">
              <img :src="item.product_img" alt="">
            </a>
            <div>
              <a class="shop_name" :href="'/detail?id='+item.id">{{item.product_name}}</a>
              <a class="shop_price" :href="'/detail?id='+item.id">价格：<span class="price_num">¥{{item.price}}</span></a>
            </div>
          </div>
        </li>
        <li v-show="v.length<3" style="display:flex;align-items:center">
          敬请期待...
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Shoplist',
  data(){
    return{
      shopdata:[],
      ambitus:[],
      Hardware:[],
      tab:[],
      tableData:[]
    }
  },
  mounted(){
    this.getdata()
    this.getTab()
  },
  methods:{
    getTab(){
      this.$get('community/home_goods').then(res=>{
        this.tableData = res.data
      })
    },
    golist(){
      this.$router.push({
        path:'/list'
      })
    },
    detail(e){
      this.$router.push({
        path:'/detail',
        query:{
          id:e.id
        }
      })
    },
    getdata(){
      this.$get("/community/commoditys",{
        sort:'1',
      }).then(res=>{
        this.shopdata = res.data
        res.data.map(item=>{
          if(item.product_sort=="社区周边"){
            this.ambitus.push(item)
          }else{
            this.Hardware.push(item)
          }
        })
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.tit{
  margin: 2rem 0;
  text-align: center;
  font-size: 20px;
  background-image:-webkit-linear-gradient(bottom, rgb(183, 182, 182), #969696, #1e1e1d);
    -webkit-background-clip:text; 
    -webkit-text-fill-color:transparent; 
}
  .tab{
    font-size: 17px;
    text-align: center;
      animation: change 2s ;
      display: flex;
      justify-content: space-between;
      span:nth-child(1){
        width: 7rem;
        display: inline-block;
        background: #000;
        padding: 10px;
        border-radius: 6px;
        border-bottom-left-radius:0;
        color: #fff;
      }
      span:nth-child(2){
        color: #ac9456;
        padding: 0 45px;
        cursor: pointer;
        border-bottom: 2px solid #000;
        height: 42px;
        line-height: 44px;
      }
  }
  @keyframes change {
    0% {
        opacity: 0.1;
        color: rgb(56, 56, 56);
    }
    100% {
        opacity: 1;
        color: #fff;
    }
}
ul{
  list-style: none;
  padding-top: 1rem;
  border: 2px solid #000;
  border-top: none;
  display: flex;
  // flex-wrap: wrap;
  // justify-content: space-around;
  animation: box 3s;
  border-radius: 6px;
  border-top-left-radius:0;
  border-top-right-radius:0;
  margin-bottom: 6rem;
  @keyframes box{
    0% {
        opacity: 0.1;
    }
    100% {
        opacity: 1;
    }
  }
  li{
    width: 25%;
    margin: 0 3rem;
    margin-bottom: 2rem;
    padding-bottom: 2rem;
    cursor: pointer;
    .shop_img{
      width: 15rem;
      height: 15rem;
      display: flex;
      align-items: center;
      overflow: hidden;
      padding-bottom: 1rem;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .shop_name{
      color:#000;
      text-decoration: none;
      display:-webkit-box;
    -webkit-box-orient:vertical;/*设置方向*/
    -webkit-line-clamp:1;/*设置超过为省略号的行数*/
    overflow:hidden;
    }
    .shop_price{
      font-size: 13px;
      color: #7d7979;
      text-decoration: none;
    }
    .price_num{
        color: #ac9456;
    }
  }
  
  .list::after {
      content: "";
      width: 51%;
      background: #000;
    }
}
@media screen and (max-width: 1200px){
  .list{
    width: 20%;
    // padding: 0 3rem;
    margin: 0 2rem;
  }
}
</style>
