<template>
  <div class="nav">
    <el-row style="width:100%">
      <el-col :span="24">
				<div class="grid-content bg-purple-dark">
					<div class="nav">
						<img src="../assets/black.png" class="logo" alt="">
						<div class="nav_tab">
							<span :class="path=='/'?'active':''" @click="$router.push('/')">首页</span>
							<span :class="path=='/order'?'active':''" @click="$router.push('/order')" v-show="login">我的订单</span>
							<span :class="path=='/address'?'active':''" @click="$router.push('/address')" v-show="login">收货地址</span>
							<span :class="path=='/cart'?'active':''" @click="$router.push('/cart')" v-show="login">购物车</span>
							<a href="https://iotsec-zone.com/home" target="_blank">社区</a>
							<a href="https://ke.iotsec-zone.com" target="_blank">课程中心</a>
						</div>
					</div>
					
					<div class="user">
						<img :src="user_img" alt="" v-show="user_img">
						<span v-show="!user_img" @click="$router.push('/login')">登录</span>
						<span v-show="user_img" @click="logout">退出登录</span>
					</div>
				</div>
			</el-col>
    </el-row>
  </div>
</template>

<script>
export default {
	data(){
		return{
			path:'',
      		user_img:'',
			login:false
		}
	},
	created(){
		this.path = this.$route.path
		this.user_img = localStorage.getItem('face')
		if(localStorage.getItem('Token')){
			this.login = true
		}else{
			this.login = false
		}
	},
	mounted(){
	},
	watch:{
		$route(val){
			this.path = val.path
			if(localStorage.getItem('Token')){
				this.$get('/community/commodity/user_info').then(res=>{
				localStorage.setItem('face',res.data.face)
				this.user_img = res.data.face
			})
			}else{
				this.user_img = ''
				localStorage.removeItem('face')
			}
		}
	},
	methods:{
		logout(){
			this.$get("/user/logout").then(res=>{
				if(res.code==200){
					this.$message({
						type:'success',
						message:'退出成功',
						offset:80
					})
					window.open('/','_self')
					// this.user_img = ''
					localStorage.removeItem('Token')
					localStorage.removeItem('face')
				}else{
					this.$message({
						type:'error',
						message:res.msg,
						offset:80
					})
				}
			})
		}
	}
};
</script>

<style lang="scss" scoped>
	.active{
		color: #ac9456;
	}
.grid-content{    
	z-index: 99;
	position: fixed;
	top: 0;
	left: 0;
  width: 100%;
  box-sizing: border-box;
	height: 4rem;
	background: #141414;
	display: flex;
	align-items: center;
	padding: 0 3rem;
	justify-content: space-between;
	.logo{
		width: 9.8rem;
	}
	.nav_tab{
		color: #a6a6a6!important;
		margin-left: 7rem;
		font-size: 14px;
		span,a{
			cursor: pointer;
		}
		a:hover{
			color: #ac9456 !important;
		}
		a{
			text-decoration: none;
			color: #a6a6a6!important;
		}
		span:hover{
			color: #ac9456;
		}
		span,a{
			margin-right: 2rem;
		}
	}
}
.nav{
	display: flex;
  align-items: center;
}
.user{
	display: flex;
	align-items: center;
	img{
		width: 3rem;
		height: 3rem;
		border-radius: 50%;
		margin-right: 1rem;
	}
	span{
		color: #fff;
		font-size: 14px;
		cursor: pointer;
	}
}
</style>